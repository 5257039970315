.homeFooter {
  background: #151515;
  padding: 46px 0 0 0;
  @media (max-width: 992px) {
    padding: 50px 0 0 0;
  }
  .footer-text-logo {
    font-size: 23px;
    margin:15px 10px;
   
  }

  a {
    color: #898989;
    &:hover {
      color: #71c4c8;
    }
  }

  .socialName {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    width: 100%;
    background-color: #81c3c9;
    padding: 23px 0px;
    margin-top: 22px;
    word-spacing: 4px;
    color: #fff;
    font-weight: bold;
    font-size: 15px;
    p {
      margin-bottom: 0;
    }
    svg {
      padding: 0 8px;
    }
  }
}
