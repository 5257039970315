.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.customCheckbox label span {
  padding: 0px 3px !important;
  margin-right: 0px !important;
  margin-left: 3px !important;
}
.customCheckbox .MuiFormControlLabel-root {
  align-items: flex-start !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.crossIcon {
  color: #0572fa;
  font-weight: bold;
  font-size: 55px !important;
  border: 1px solid #0572fa;
  border-radius: 27px;
}
.crossIcon:hover {
  background: #fcfcfc;
}
.recorderBlock {
  width: 80%;
  margin: auto;
  border-radius: 24px;
  margin-bottom: 43px;
}
.MuiDialog-paperFullScreen {
  background: #ffffff87 !important;
}
.video-recorder__Wrapper-sc-7k20rv-0 {
  width: 100% !important;
  margin: auto;
  border-radius: 25px;
  margin-bottom: 25px;
}
._dt3-T {
  border-radius: 25px !important;
}
._1lB9c {
  border-radius: 25px 25px 0px 0px !important;
  background: #71c4c8 !important;
}
._1ceqH {
  width: 80%;
  margin: -31px auto !important;
  border-radius: 25px;
  overflow: hidden;
  height: 100%;
  text-align: center;
}
._1ceqH ._1dpop {
  background: #71c4c8 !important;
}
._1ceqH ._1dpop:hover {
  background: #fff !important;
}
._1ceqH ._1dpop:hover ._3wi1g {
  fill: #026cd5 !important;
}
._1ceqH ._1lB9c {
  display: inline !important;
  justify-content: space-between;
  background: #bd9f61;
  align-items: center;
  padding: 0px 0px;
  color: #fff;
  border-radius: 3px 3px 0 0;
  text-align: center;
  margin: 0;
}
span._3-aC9 {
  height: auto !important;
}
._1ceqH ._dt3-T {
  min-height: 480px !important;
}

figure.d-block.mb-0.position-relative {
  height: 100%;
}

figure.d-block.mb-0.position-relative a img,
figure.d-block.mb-0.position-relative audio {
  /* position: absolute; */
  vertical-align: middle;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 125px;
}
figure.d-block.mb-0.position-relative video {
  /* position: absolute; */
  vertical-align: middle;
  top: 0;
  bottom: 0;
  margin: auto;
  /* height: 215px; */
}
.viewvideo{
  height: 215px;
}
.background_color {
  background: #71c4c8 !important;
  color: #fff !important;
}

.MuiSlider-thumb {
  width: 18px !important;
  height: 18px !important;
  display: flex;
  outline: 0;
  position: absolute;
  box-sizing: border-box;
  margin-top: -8px !important;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  margin-left: 0px !important;
  border-radius: 50%;
  justify-content: center;
  background-color: currentColor;
}
.MuiSlider-mark {
  width: 18px !important;
  top: 5px;
  height: 18px !important;

  opacity: 1;
  position: absolute;
  border: 1px solid #eee;
  border-radius: 13px !important;
  background: #d6d6d6 !important;
}
.MuiSlider-markActive {
  opacity: 1 !important;
  background-color: #fff;
}
.MuiSlider-markLabel {
  font-size: 1rem !important;
  transform: translateX(-0%) !important;
}
.footer-block{
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
}
.background_color:hover{
  background: #4cb5ba !important;
}
.timerVideoCount {
  font-size: 4rem;
  text-align: center;
}
.videorecorderDialouge .kHmnKJ {
  margin: auto;
  border-radius: 0px;
}
.videorecorderDialouge .MuiPaper-root.MuiDialog-paper.MuiPaper-rounded {
  max-height: 95%;
}

@media only screen and (max-width: 600px) {
  ._1ceqH {
    width: 100%;
    margin: -31px auto !important;
    border-radius: 25px;
    overflow: hidden;
    height: 100%;
    text-align: center;
  }
  .react-confirm-alert-overlay .react-confirm-alert .react-confirm-alert-body,
  .react-confirm-alert-overlay
    .react-confirm-alert
    .react-confirm-alert-body
    .react-confirm-alert-button-group {
    text-align: center;
    margin: auto;
    width: 100%;
    display: block;
  }

  .react-confirm-alert-overlay
    .react-confirm-alert
    .react-confirm-alert-body
    .react-confirm-alert-button-group
    button {
    margin: 3px;
    margin-top: 13px;
  }
  .MuiDialog-root.videorecorderDialouge {
    width: 100% !important;
    z-index: 5555555555 !important;
}
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@-webkit-keyframes argh-my-eyes {
  0%   { background: #71c4c8; }
  49% { background: #71c4c8; }
  50% { background: #000; }
  99% { background: #000; }
  100% { background: #71c4c8; }
}
@-moz-keyframes argh-my-eyes {
  0%   { background: #71c4c8; }
  49% { background: #71c4c8; }
  50% { background: #000; }
  99% { background: #000; }
  100% { background: #71c4c8; }
}
@keyframes argh-my-eyes {
  0%   { background: #71c4c8; }
  49% { background: #71c4c8; }
  50% { background: #000; }
  99% { background: #000; }
  100% { background: #71c4c8; }
}
button.startmemebershipbtn {
-webkit-animation: argh-my-eyes 1s infinite;
-moz-animation:    argh-my-eyes 1s infinite;
animation:         argh-my-eyes 1s infinite;
}

button.changeProfilepicturebtn.Mui-disabled.Mui-disabled {
  background: #e6e2e2;
  color: #fff !important;
}

button.signoutbtn.Mui-disabled.Mui-disabled {
  background: #e6e2e2;
  color: #fff !important;
}
li.mb-3.col-lg-6.active div {
  background: #00696e;
  color: #fff;
}

.waitingtext{
  position: absolute;
  color: #fff;
  font-size: 20px;
  z-index: 5;
  margin: auto;
  left: 50%;
  bottom: 25%;
}


/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #71c4c8;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}