$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px,
) !default;
$theme-colors: (
  "color1": #71c4c8,
  "color2": rgba(#000000, 0.6),
  "color3": #f6f6f6,
  "color4": #a3a3a3,
  "color5": #666666,
  "color6": #f8f8f8,
  "black": #000000,
  "red": #e9101f,
  "red1": #eb5757,
);

$primary: #71c4c8;
.cp {
  cursor: pointer;
}

@import "~bootstrap/scss/bootstrap";

@import "./assets/css/preset.scss";
@import "./assets/css/animate.min.css";

body {
  font-family: "Roboto", sans-serif;
}
* {
  outline: none !important;
}
a {
  text-decoration: none !important;
  transition: ease 0.3s;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: red;
}

.min-vh-50 {
  min-height: 50vh;
}
.min-vh-75 {
  min-height: 75vh;
}

@media (min-width: 1500px) {
  .container {
    min-width: 1440px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #3f51b5 !important;
}
.MuiCheckbox-colorSecondary.Mui-checked {
  color: #3f51b5 !important;
}

.MuiMenuItem-root {
  font-weight: 500 !important ;
}
.MuiButton-root {
  text-transform: none !important;
}

.checkLabelCustom {
  label {
    margin: 0;
  }
}

.myCusTomModal {
  max-width: 637px;
  //max-width: 96%;
  max-height: 90vh;
  margin: 0 auto;
  @media (max-width: 600px) {
    max-height: 75vh;
  }
}

.shadow-md {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
}

.shadow-md-price {
  box-shadow: 0px 0px 7px 2px #cbc6c687;
  border: 1px solid #eee;
}

header {
  &.absoluteHeader {
    position: absolute;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;

    nav {
      ul {
        li {
          a {
            color: #ffffff;
            img {
              filter: invert(100%) brightness(1000%);
            }
          }
        }
      }
    }
  }
  &.innerHeader {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  }
  nav {
    ul {
      li {
        a {
          margin: 0 10px;
          display: block;
          padding: 5px 0px;

          font-size: 16px;
          font-weight: 400;
          color: #000000;
          &:hover,
          &.active {
            color: $primary;
          }
        }
      }
    }
  }
}
.footerButtonColor:hover {
  color: #fff;
}
.sign_in_text {
  margin-top: -15px;
  @media (max-width: 600px) {
    margin-top: -29px;
    margin-right: 16px;
  }
}
.padding-0 {
  @media (max-width: 700px) {
    padding: 0px;
  }
}

.custom_form {
  .col-md-12.mb-4 {
    // @media (max-width: 756px) {
    //   padding-left: 0;
    // }
    .MuiFormControl-root {
      transform: inherit;
    }
  }
  .col-md-6.mb-4 {
    // @media (max-width: 756px) {
    //   padding-left: 0;
    // }
    .MuiFormControl-root {
      transform: inherit;
    }
  }
}

.heroWraper {
  margin-top: 100px;
  .imgBox {
    // position: absolute;
    // width: 818.32px;
    // height: 648.54px;
    // position: relative;
    // background: #71c4c8;
    // opacity: 0.1;
    // transform: rotate(13.56deg);
    // border-radius: 50% 300px 300px 50%;
    position: relative;
    img {
      width: 100%;
      display: block;
    }
    .heroImg {
      position: absolute;
      top: 0;
      left: 10%;
      width: 90%;
    }
  }
  h1 {
    color: #000000;
    font-weight: 300;
    b {
      font-weight: 700;
    }

    p {
      line-height: normal;
      margin-bottom: 0;
      strong {
        color: #000000;
        opacity: 1;
      }
    }
  }
  p {
    font-weight: 400;
    line-height: 28px;
    opacity: 1;
    color: #000000;
  }
  button {
    height: 59px;
    width: 334px;
    border-radius: 4px;
    font-size: 24px;
    font-weight: 700;
  }
}

.shareTHeFutureWraper {
  color: #fff;

  &::before {
    background-color: #81c3c9;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(0 26%, 100% 0, 100% 100%, 0 74%);
  }
  .bgImg {
    display: none;
  }
  .container {
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // z-index: 2;
    padding: 10% 15px;
  }
  .contentBox {
    max-height: 300px;
  }
  h3 {
    font-weight: 300;
    color: #ffffff;
  }

  p {
    font-weight: 300;
    line-height: 37px;
    opacity: 0.8;
    color: #ffffff;
  }
  a {
    font-size: 24px;
    font-weight: 700;
    color: #ffffff;
    &:hover {
      color: #000000;
    }
  }
}

.anyOneWraper {
  .container {
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // z-index: 2;
  }
  h3 {
    font-size: 64px;
    font-weight: 300;
    color: #000000;
  }

  p {
    font-weight: 400;
    line-height: 28px;
    opacity: 0.9;
    color: #000000;
  }
  a {
    font-size: 24px;
    font-weight: 700;
    color: #000000;
    &:hover {
      color: $primary;
    }
  }
}

.whatIsItLinkWrap {
  padding-bottom: 160px;
  .container {
    margin-top: -500px;
    // transform: translateY(-110%);
  }
  h3 {
    font-size: 24px;
    font-weight: 700;
    color: #000000;
  }
  p {
    font-size: 18px;
    font-weight: 400;
    color: #000000;
    opacity: 0.6;
    margin-bottom: 50px;
  }
  .sliderBox {
    background-color: #ffffff;
    box-shadow: 0 4px 11px 0 rgba(#000000, 0.1);
    padding: 40px 80px;
    border-radius: 10px;
    h4 {
      font-size: 22px;
      font-weight: 400;
      line-height: 36px;
      color: #000000;
    }
    h2 {
      font-size: 22px;
      font-weight: 700;
      color: #000000;
    }
    h6 {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #000000;
      opacity: 0.4;
    }

    .slick-arrow {
      width: 40px;
      height: 40px;
      &::before {
        display: none;
      }
      &.slick-prev {
        background-image: url("./assets/images/icon2.svg");
      }
      &.slick-next {
        background-image: url("./assets/images/icon3.svg");
      }
    }
  }
}

.button-text-span p {
  color: #fff;
  font-weight: bold;
  margin: 0px;
}
// button.MuiButtonBase-root.MuiButton-root.MuiButton-text:hover {
//   background: #419397 !important;
// }
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.customButton:hover {
  background: #47a3a7 !important;
}
// .oval-block {
//   background: #e3f3f4;
//   height: 110%;
//   width: 97%;
//   position: absolute;
//   border-radius: 62% 90% 88% 76%/77% 76% 87% 73%;
//   left: 5px;
//   transform: rotate(187deg);
//   top: -12px;
// }
.letsGiveYouTourWrap {
  .head {
    margin-bottom: 50px;
    h3 {
      font-size: 24px;
      font-weight: 700;
      color: #000000;
    }
    p {
      font-size: 18px;
      font-weight: 400;
      color: #000000;
      opacity: 0.6;
    }
  }
  .btnBox {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: ease 0.3s;
    &:hover {
      transform: translate(-50%, -50%) scale(0.8);
    }
  }
}

.getStartWrap {
  padding: 70px 0;
  img {
    height: 30px;
    margin-top: 5px;
  }
  h3 {
    font-weight: 400;
    font-style: italic;
    font-size: 36px;
  }
  h2 {
    font-size: 36px;
    font-weight: 300;
    color: #000000;
  }
  p {
    font-size: 18px;
    font-weight: 400;
    color: #000000;
    opacity: 0.6;
  }
  a {
    color: $primary;
    font-weight: 700;
    font-size: 24px;
    width: 324px;
    max-width: 100%;
    border: 1px solid #71c4c8;
    border-radius: 4px;
    padding: 10px 0;
  }
}

.brandWrap {
  margin-bottom: 180px;
  .row {
    background: #f6f6f6;
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 20px;
    > div {
      margin: 20px 0;
    }
    img {
      opacity: 1;
    }
  }
}

.registerWrap {
  background-image: url("./assets/images/image--000.jpg");
  background-attachment: fixed;
  background-size: cover;
  min-height: 100vh;
  padding-top: 150px;
  display: flex;
  align-items: center;
  .registerInwrap {
    background: rgba($primary, 0.7);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    .leftBox {
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
      border-radius: 10px;
      padding: 76px;
    }
    .rightBox {
      padding: 76px;
    }
  }

  .passwordWrap {
    .icoSufix {
      top: 0;
      right: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.registerStepWrap {
  padding: 96px 0;
  background-color: #f8f8f8;
  background-image: url("./assets/images/fingerBg.svg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  .card {
    width: 705px;
    max-width: 100%;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    padding: 30px 90px 50px;
    &.bigCard {
      width: 884px;
    }
  }
  .head {
    margin-bottom: 45px;
    h5 {
      color: rgba(0, 0, 0, 0.6);
      opacity: 0.6;
      font-size: 18px;
      font-weight: 400;
    }
  }

  .subHead {
    color: rgba(0, 0, 0, 0.6);
    opacity: 0.6;
  }
  .info {
    border-left: 3px solid rgba(#000000, 0.2);
    padding-left: 15px;
    font-size: 24px;
    color: rgba(#000000, 0.6);
    font-weight: 300;
    margin-top: 30px;
  }

  .planBox {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
  }
  .payBox {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
    height: 220px;
  }
}

.dashboardWrap {
  background-image: url("./assets/images/img1.svg");
  background-position: right bottom;
  background-attachment: fixed;
  background-repeat: no-repeat;
  .userSlider {
    .uploadImage {
      position: relative;
      input {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        cursor: pointer;
        -webkit-appearance: none;
        opacity: 0;
      }
      img {
        display: block;
      }
    }
    .slick-dots {
      padding: 25px 0;
      position: static;
      li {
        &.slick-active {
          button {
            &:before {
              color: #71c4c8;
            }
          }
        }
        button {
          &:before {
            font-size: 10px;
            opacity: 1 !important;
            color: #c4c4c4;
          }
        }
      }
    }
  }

  .addVideoImage {
    figure {
      border-radius: 10px;
      overflow: hidden;
      cursor: pointer;
      background-color: #000000;
      &:hover {
        img {
          opacity: 1;
        }
      }
      img {
        opacity: 0.8;
        transition: ease 0.3s;
      }
      .play {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(#000000, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 1;
        transition: ease 0.3s;
        &:hover {
          background-color: rgba(#000000, 0.6);
        }
      }
    }
  }
  &.dashboardWrapsub {
    h3 {
      font-size: 20px !important;
    }
    h4 {
      font-size: 16px;
    }
    .fontsmall {
      font-size: 16px !important;
    }
    .fontsmalltext {
      font-size: 15px !important;
      margin-bottom: 10px !important;
    }
  }
}

.helpCenterWrap {
  figure {
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    .play {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#000000, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      transition: ease 0.3s;
      &:hover {
        background-color: rgba(#000000, 0.5);
      }
    }
  }
}

a.d-flex.align-items-center.rounded:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  transform: translateX(-50%);
}

.dropBoxWrap {
  z-index: 99;
  background: #ffffff;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  width: 437px;
  top: calc(100% + 10px);
  @media (max-width: 575px) {
    width: 310px;
  }
}

// Responsive Style
@media (max-width: 1500px) {
  .whatIsItLinkWrap {
    .container {
      margin-top: -400px;
    }
  }
}

.MuiIconButton-colorPrimary {
  color: #71c4c8 !important;
}
.primary-color {
  color: #71c4c8 !important;
}

.customlist {
  ul {
    padding-left: 0;
    margin-bottom: 0;
    li {
      list-style: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      border-bottom: 1px solid #eee;

      .customname {
        width: 100%;
        text-align: left;
        font-size: 14px;
        padding: 0 10px;
      }
      .customcheck {
        position: relative;
      }
      .customcheck input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        opacity: 0;
        cursor: pointer;
        position: absolute;
        width: 400px;
        height: 42px;
        right: 0;
        z-index: 1;
      }

      .customcheck label {
        position: relative;
        cursor: pointer;
      }

      .customcheck label:before {
        content: "";
        -webkit-appearance: none;
        background-color: transparent;
        // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 10px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 5px;

        border-radius: 50%;
        border: 1px solid #eee;
      }
      .customcheck input:checked + label::before {
        background: #71c4c8;
      }
      .customcheck input:checked + label:after {
        content: "";
        display: block;
        position: absolute;

        left: 8px;
        width: 5px;
        height: 12px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        top: 7px;
      }
    }
  }
}

.MuiLinearProgress-barColorPrimary {
  background-color: #71c4c8 !important;
}

.bannerImgWrapper {
  background: url("./assets/images/odd.png") center center no-repeat;
  background-size: 110% 100%;

  max-width: 500px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 1200px) {
    transform: scale(1.4);
    transform: scale(1.1);
    margin-top: 5%;
    margin-left: -5%;
  }
  @media (min-width: 1400px) {
    transform: scale(1.3);
    margin-top: 0;
    margin-left: 5%;
  }
  @media (min-width: 1600px) {
    transform: scale(1.5);
    margin-top: 5%;
    margin-left: -5%;
    margin-bottom: 15%;
  }
  .bannerImageInner {
    background: url("./assets/images/banner-imge-inner.png") center center
      no-repeat;
    background-size: cover;

    width: 100%;
    height: 400px;
    border-radius: 20px;
    overflow: hidden;
    mask-image: url(./assets/images/banner-imge-inner.png);
    mask-size: 496px;
    mask-repeat: no-repeat;
    mask-position: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media (max-width: 1170px) {
    background-size: contain;
    max-width: 337px;
    height: 255px;
    .bannerImageInner {
      height: 255px;
      mask-size: 312px;
    }
  }
}

.AddIcon {
  position: absolute;
  color: rgb(113, 196, 200);
  left: 84px;
  border: 2px solid #fff;
  border-radius: 50%;
  background: #fff;
  display: flex;
  font-size: 33px !important;
}
.userIcon {
  cursor: pointer;
  width: 114px !important;
  height: 114px !important;
}
.offlineCls {
  position: fixed;
  bottom: 0;
  background: #71c4c8;
  width: 100%;
  text-align: center;
  padding: 16px;
  color: #fff;
  font-size: 20px;
}

.selected-plan {
  border: 2px solid #8080809e;
  background: #eeeeee;
}

.MuiCircularProgress-colorPrimary {
  color: #71c4c8 !important;
}

tr.fold {
  display: none;
  // background: #71c4c8;
  transition: height 0.3s ease;
  vertical-align: middle;
  // color: #fff;

  border-bottom: 2px solid #81c3c9;
  border-right: 2px solid #81c3c9;
  border-left: 2px solid #81c3c9;
  @media (max-width: 560px) {
    border-right: 4px solid #81c3c9;
    border-left: 4px solid #81c3c9;
  }

  &.open {
    display: table-row;
    transition: height 0.3s ease;
  }
  td button {
    // background: #fff !important;
    // color: #71c4c8 !important;
    &:hover,
    &.active {
      color: #fff !important;
    }
  }
}
.comparison {
  display: flex !important;
  margin-top: 8px !important;
  margin-left: 5px !important;
}

.TABLEtR {
  border-top: 2px solid #81c3c9;
  border-right: 2px solid #81c3c9;
  border-left: 2px solid #81c3c9;
  vertical-align: middle;
  @media (max-width: 560px) {
    border-right: 4px solid #81c3c9;
    border-left: 4px solid #81c3c9;
  }
}
.TABLEtR td,
tr.fold td {
  vertical-align: middle;
}

.tableBlock {
  @media (max-width: 560px) {
    overflow-x: auto;
  }
}
.rectangle #tawkchat-status-text-container {
  width: 60px;
  height: 60px;
  display: block;
  border-radius: 50%;
}

.rectangle #tawkchat-status-text-container {
  padding: 0;
  position: absolute;
  z-index: 999998;
  width: 40px;
  height: 100%;
  border-radius: 35px;
  border: 0 none;
  text-align: center;
  right: -8px;
  bottom: -12px;
}

.howworks {
  p {
    line-height: normal;
    font-size: 18px;
    line-height: 26px;
    @media (min-width: 1600px) {
      line-height: normal;
      font-size: 24px;
    }
  }
}

.imagesection {
  display: inline-block;
  min-height: 108px;
  margin-bottom: 20px;
}

.pricingWrap {
  background: #fff url(./assets/images/pricingprint.png) no-repeat;
  background-size: auto 100%;
  background-position: left bottom;
  .greybg {
    background: #f6f6f6;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 900px !important;
    max-width: 100% !important;
    border-radius: 15px !important;
  }
  .planBox {
    background: #fff;
    .list-unstyled {
      min-height: 66px;
    }
  }
}
.bluebgsection {
  background: #71c4c8 url(./assets/images/fingerprint.png) no-repeat left bottom;
  background-size: contain;
}

.padding-custom {
  padding: 0 45px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
}
.light_grey {
  background: #f0f1f2;
  border: 1px solid #e3dede;
}

.registerStepWrap {
  .payBox {
    @media (max-width: 768px) {
      height: 125px;
    }
  }
}

.custom-sm-padding-card {
  .iconBox.mar-b-15.mar-b-md-35 {
    margin-left: 12px;
  }
  .fs-22.fs-md-36.font-weight-Bold {
    margin-left: 12px;
  }
  .subHead.fs-14.fs-md-18.mar-b-30 {
    margin-left: 12px;
  }
  .d-flex.pb-4.w-100 {
    margin-left: 8px;
  }
  .card div.pad-t-30 {
    padding: 12px;
  }
  .pad-t-30 {
    padding: 12px;
  }
}

@media (max-width: 768px) {
  .custom-sm-padding {
    .iconBox.mar-b-15.mar-b-md-35 {
      margin-left: 12px;
    }
    .fs-22.fs-md-36.font-weight-Bold {
      margin-left: 12px;
    }
    .subHead.fs-14.fs-md-18.mar-b-30 {
      margin-left: 12px;
    }
    .d-flex.pb-4.w-100 {
      margin-left: 8px;
    }
    .card div.pad-t-30 {
      padding: 12px;
    }
    .pad-t-30 {
      padding: 12px;
    }
  }
  .custom-sm-padding-card {
    .iconBox.mar-b-15.mar-b-md-35 {
      margin-left: 12px;
    }
    .fs-22.fs-md-36.font-weight-Bold {
      margin-left: 12px;
    }
    .subHead.fs-14.fs-md-18.mar-b-30 {
      margin-left: 12px;
    }
    .d-flex.pb-4.w-100 {
      margin-left: 8px;
    }
    .card div.pad-t-30 {
      padding: 12px;
    }
    .pad-t-30 {
      padding: 12px;
    }
  }
}

// .pl-custom-sm-0 {
//   @media (max-width: 759px) {
//     padding: 0px !important;
//   }
// }
