.stayConnected {
  background: #81c3c9;
  padding: 100px 0px 100px 0px;

  .row {
    padding: 100px 200px 30px 200px;
    @media (max-width: 1440px) {
      padding: 100px 80px 30px 80px;
    }
    @media (max-width: 992px) {
      padding: 50px 0;
    }
  }
  h5 {
    width: 100%;
    color: #bfe0e3;
  }
  h1 {
    width: 100%;
    color: #ffffff;
    font-size: 3.5rem;
    font-weight: 700;
  }

  input {
    height: 44px;
  }

  .btn-subs {
    width: 200px;
    background-color: #1d1d1d;
    color: #fff;
    font-size: 1.25rem;
    font-weight: 600;
    &:hover {
      border: 1px solid #1d1d1d;
      color: #1d1d1d;
      background-color: transparent;
    }
  }
}
