@media (max-width: 1500px) {
  :root {
    font-size: 14px;
  }

  header {
    nav {
      ul {
        li {
          a {
            padding: 0px;
          }
        }
      }
    }
  }

  .heroWraper {
    button {
      font-size: 20px;
      width: auto;
      padding: 10px 60px;
    }
  }

  .getStartWrap {
    padding: 80px 0;
  }
  .brandWrap {
    margin-bottom: 90px;
  }

  .registerWrap {
    padding-top: 5.8rem;

    .registerInwrap {
      .leftBox {
        padding: 2.6rem;
      }
      .rightBox {
        padding: 3rem;
      }
    }
  }
}

@media (max-width: 1199px) {
  .registerWrap {
    .registerInwrap {
      .leftBox {
        padding: 30px;
      }
      .rightBox {
        padding: 50px 30px;
      }
    }
  }
  .whatIsItLinkWrap .container {
    margin-top: -300px;
  }

  header {
    nav {
      ul {
        li {
          a {
            padding: 0 10px;
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  header {
    &.absoluteHeader {
      nav {
        ul {
          li {
            a {
              color: #222222;
              &.active {
                color: #71c4c8;
              }
              img {
                filter: none;
              }
            }
          }
        }
      }
    }
    nav {
      position: fixed;
      left: -350px;
      top: 0;
      height: 100vh;
      width: 250px;
      background-color: #f6f6f6;
      z-index: 99;
      box-shadow: 7px 0 30px rgba(#323232, 0.1), 7px 0 30px rgba(#323232, 0.1),
        7px 0 30px rgba(#323232, 0.1);
      transition: ease 0.3s;

      &.active {
        left: 0;
      }

      .m-Logo {
        img {
          height: 30px;
        }
      }

      .closeBtn {
        i {
          color: #d82424;
        }
      }

      ul {
        padding-top: 20px;

        li {
          a {
            display: block;
            padding: 15px 15px;
            border-bottom: 1px solid #e6e5e5;
          }
        }
      }
    }
  }

  .heroWraper {
    padding-top: 35px;
    margin-top: 0px;
  }

  .shareTHeFutureWraper {
    background-color: #71c4c8;
    margin: 80px 0;
    padding: 60px 0;
    .container {
      padding: 0px 15px;
    }
    .bgImg {
      display: none;
    }

    .container {
      position: static;
      transform: none;
    }
  }

  .whatIsItLinkWrap {
    background-color: #f6f6f6;
    margin: 80px 0;
    padding: 60px 0;

    .bgImg {
      display: none;
    }

    .container {
      position: relative;
      margin: 0 auto;
    }
  }

  .getStartWrap {
    padding: 100px 0;
  }

  .brandWrap {
    margin-bottom: 100px;
  }

  .registerStepWrap {
    .card {
      padding: 30px 50px 136px;
    }
  }
  .registerWrap .passwordWrap .icoSufix {
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }
}

@media (max-width: 767px) {
  .MuiSlider-track {
    height: 2px;
    display: block;
    top: 13px;
    position: absolute;
    border-radius: 1px;
    background-color: currentColor;
  }
  .MuiSlider-rail {
    width: 100%;
    height: 2px;
    display: block;
    top: 13px;
    opacity: 0.38;
    position: absolute;
    border-radius: 1px;
    background-color: currentColor;
  }
  .MuiSlider-thumb {
    top: 13px;
  }
  .MuiSlider-markLabel {
    top: 40px !important;
  }
  .MuiSlider-marked {
    margin-bottom: 20px;
    transform: scale(0.8);
  }
  .heroWraper {
    .imgBox {
      margin-bottom: 30px;
    }
  }

  .anyOneWraper {
    h3 {
      padding-top: 30px;
    }
  }

  .whatIsItLinkWrap {
    .sliderBox {
      padding: 30px 15px 50px;
    }
  }

  .registerWrap {
    padding-top: 100px;
  }

  .registerStepWrap {
    padding: 45px 0;
    .card {
      padding: 20px 20px 60px;
      max-width: calc(100% - 30px);
    }
    .head {
      margin-bottom: 20px;
    }
    .info {
      font-size: 16px;
    }
  }
  .fs-14.fs-md-18.coupon_code_block {
    float: none !important;
    width: 100%;
    margin-bottom: 14px !important;
  }

  .margin-top-30 {
    margin-top: 30px !important;
  }
  .min-w-150 {
    width: 150px;
    min-width: 150px !important;
  }
  button.MuiButtonBase-root.MuiButton-root.MuiButton-text.text-color1.border.fs-19.pad-y-7.pad-x-15 {
    width: 100%;
    justify-content: flex-start;
  }
}

@media (max-width: 1366px) and (min-width: 992px) {
  .fs-14.fs-md-18.coupon_code_block {
    float: none !important;
    width: 100%;
    margin-bottom: 14px !important;
  }
  .registerWrap .passwordWrap .icoSufix {
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }
}

@media (max-width: 575px) {
  .breadcrumb {
    font-size: 0.9rem;
    .material-icons {
      font-size: 1rem;
      vertical-align: text-bottom;
    }
  }
  .react-confirm-alert-body > h1 {
    font-size: 1.5rem;
  }
  .fs-10 {
    font-size: 10px !important;
  }
}
@media (max-width: 382px) {
  button.MuiButtonBase-root.MuiButton-root.MuiButton-text.text-color1.border.fs-19.pad-y-7.pad-x-15
    .MuiButton-label {
    font-size: 15px;
  }
}

@media (max-width: 360px) {
  .shareTHeFutureWraper,
  .anyOneWraper {
    p {
      br {
        display: none;
      }
    }
  }
  .min-w-150 {
    width: 150px;
    min-width: 150px !important;
  }
}

@media (max-width: 767px) {
  // .MuiFormControl-root {
  //   transform: scale(1);
  // }
  .col-md-8.mb-3.mobile_no {
    margin-top: 10px;
  }
}
